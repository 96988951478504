<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
//import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
import { format } from "date-fns";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    /* ckeditor: CKEditor.component ,*/
  },
  data() {
    return {
      title: "Nouveau message",
      items: [
        {
          text: "Message",
        },
        {
          text: "Nouveau",
          active: true,
        },
      ],
      valueType: null,
      valueTypeM: null,
      valueExp: null,
      valueBadge: null,
      value: null,
      value1: null,
      type: [
        {
          name: "PARTICULIER",
        },
        {
          name: "CONDUCTEUR",
        },
        {
          name: "TAXI",
        },
        {
          name: "BUS",
        },
      ],
      experience: [
        {
          name: "R-Débutant",
        },
        {
          name: "R-Qualifié",
        },
        {
          name: "R-Ambassadeur",
        },
      ],
      badge: [
        {
          name: true,
        },
        {
          name: false,
        },
      ],
      tyMessage: [
        {
          name: "Notification",
          value: 0,
        },
        {
          name: "Mail",
          value: 1,
        },
        {
          name: "Notification + Mail",
          value: 2,
        },
      ],
      tyMedia:[
        {
          name:"",
          value:""
        },
        {
          name:"Image",
          value:"image"
        },
        {
          name:"Vidéo",
          value:"video"
        }
      ],
      sujet_message:"",
      message:"",
      messageSelect: null,
      MediaSelect:null,
      messageContent: [
        {
          sujet: "",
          message: "",
        },
      ],
      url_link:"",
      options: ["Sexe", "Taille", "Vehicule", "je vois"],
      users: [],
      historique:[],
      usersdataId: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "receiver", sortable: true, label: "Message envoyé à" },
        { key: "typeMessage", sortable: true, label: "Type de message" },
        { key: "subject", sortable: true, label: "Sujet" },
        { key: "text", label: "Contenu du message" },
        { key: "date", sortable: true, label: "Date" },
      ],
      showSucess:false,
      showEchec:false,
      showDelete: false,
      showEdit: false,
      showMessage: false,
      editor: ClassicEditor,
      editorData: "<p>Salut</p>",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.users.length;
    },
  },
  async mounted() {
    //Chargement des données
    /* const sendMessage = await apiRequest(
      "POST",
      "admin/message-users",
      {
        idUser: this.usersdataId,
        type
      },
    ) */
    const Data = await apiRequest(
      "GET",
      "admin/messages",
      undefined,
      false
    );

    if(Data && Data.data){
      console.log("Historique:", Data.data)
      const historiqueTable = Data.data.map((historiqueMsg)=>{
        return{
          id:historiqueMsg.id,
          receiver: historiqueMsg.receiver,
          typeMessage: historiqueMsg.typeMsg == 0 ? "Notification" : historiqueMsg.typeMsg == 1 ? "Mail" : historiqueMsg.typeMsg == 2 ? "Notification & Mail" : "",
          subject: historiqueMsg.subjectMsg,
          text: historiqueMsg.textMsg,
           date: format(
                new Date(historiqueMsg.dateMsg),
                "dd/MM/yyyy hh:mm:ss"
              ),
        };
      });
      this.historique = historiqueTable;
    }
    
    // Set the initial number of items
    this.totalRows = this.historique.length;
  },
  methods: {
    EditModal() {
      this.submitted = false;
      this.showEdit = false;
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
   /*  onChangeType(actionName) {
      console.log("Type Users:", actionName.name);
      //console.log("user id:", this.users.id)
      this.users = this.users.filter(function(utilisateur) {
        return utilisateur.type == actionName.name;
      });
      this.usersdataId = [];
      for (let i = 0; i < this.users.length; i++) {
        this.usersdataId.push(this.users[i].id);
      }
      console.log("users id:", this.usersdataId);
    },
    onChangeExpe(actionName) {
      console.log("Type Users:", actionName.name);

      this.users = this.users.filter(function(utilisateur) {
        return utilisateur.experience == actionName.name;
      });
      this.usersdataId = [];
      for (let i = 0; i < this.users.length; i++) {
        this.usersdataId.push(this.users[i].id);
      }
      console.log("users id:", this.usersdataId);
    },
    onChangeBadge(actionName) {
      console.log("Type Users:", actionName.name);
      this.users = this.users.filter(function(utilisateur) {
        console.log("Utilisateur selon expérience:", utilisateur);
        return utilisateur.badge == actionName.name;
      });
      this.usersdataId = [];
      for (let i = 0; i < this.users.length; i++) {
        this.usersdataId.push(this.users[i].id);
      }
      console.log("users id:", this.usersdataId);
    },
    AnnulerFilter() {
      this.users = this.filterOn;
    },
    async sendMessage(){
      const message = await apiRequest(
        "POST",
        "admin/message-users",
        {
          idUsers: this.usersdataId,
          typeMessage: this.messageSelect.value,
          subject: this.sujet_message,
          text: this.message,
          media_type: this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link
        },
        false
      );
      if (message && message.data) {
        
        console.log(message);
        this.showSucess = true;
      } else {
        this.showEchec = true;
      }
    } */
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Utilisateurs</a>
                </template>
                
                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="historique"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                      <template v-slot:cell(id)="row">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          :id="`customercheck${row.value}`"
                          @click="getId"
                        />
                        <label
                          class="custom-control-label"
                          :for="`customercheck${row.value}`"
                          >&nbsp;</label
                        >
                      </div>
                    </template>

                    <template v-slot:cell(badge)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'false',
                          'badge-soft-success': `${row.value}` === 'true',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <!-- -->
    <b-modal v-model="showMessage" title="Nouveau Message" centered>
      <form>
        <label class="control-label">Choisir le type de message:</label>
        <multiselect
          v-model="messageSelect"
          :options="tyMessage"
          label="name"
          track-by="value"
        ></multiselect>

        <div class="form-group">
          <input
            type="text"
            v-model="sujet_message"
            class="form-control"
            placeholder="Sujet"
          />
        </div>

        <div class="form-group">
          <!-- <ckeditor
            v-model="messageContent.message"
            :editor="editor"
          ></ckeditor> -->
          <textarea id="" v-model="message" class="form-control mb-2" placeholder="Entrez votre message" cols="55" rows="10"></textarea>
          <multiselect
          v-model="MediaSelect"
          :options="tyMedia"
          label="name"
          track-by="value"
        ></multiselect>
        <input
            type="text"
            v-model="url_link"
            class="form-control"
            placeholder="Lien du média"
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModal = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="sendMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    
      <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucess"
      title="Message envoyé avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSucess = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchec"
      title="L'envoie a échoué"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchec = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>
    <!-- -->
  </Layout>
</template>

<style>
.custom-checkbox{
  width: 35px;
  height: 30px;
  cursor: pointer;
}

.custom-checkbox .checkmark{
  width: 100%;
  height: 100%;
  border: 2px solid #222;
  display:inline-block; 
  border-radius: 3px; 
  background: #009500 url(https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/White_check.svg/1200px-White_check.svg.png) center/1250% no-repeat;
  transition: background-size 0.25s cubic-bezier(0.87, -0.16, 0.01, 1.35);;
}

.custom-checkbox input:checked + .checkmark{
  background-size: 60%;
  transition: background-size 0.25s ease;
}
.custom-checkbox input{
  display:none;
}
</style>